<template>
  <div class="Praise_wrapper">
    <div class="select_wrap">
      <div>
        <span>请输入ID：</span>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入ID搜索 "
          v-model="CollectLikesInfo.userId"
        ></el-input>
      </div>
      <div>
        <span>请输入名称：</span>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入姓名搜索 "
          v-model="CollectLikesInfo.userName"
        ></el-input>
      </div>
      <div>
        <span>请输入手机号码：</span>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入手机号搜索 "
          v-model="CollectLikesInfo.userMobile"
        ></el-input>
      </div>
      <div>
        <span>完成状态：</span>
        <el-select v-model="CollectLikesInfo.completeStatus" placeholder="请选择" clearable>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        <span>核销状态：</span>
        <el-select v-model="CollectLikesInfo.status" placeholder="请选择" clearable>
          <el-option
            v-for="item in statusoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <el-button
        type="primary"
        class="ml10 mt10"
        icon="el-icon-search"
        @click="searchPage"
      >搜索</el-button>
      <el-button type="success" class="ml10 mt10" icon="el-icon-download" @click="exportData">导出表格</el-button>
    </div>
    <el-table class="mt20" :data="CollectLikesList" border stripe size="mini" style="width: 100%">
      <el-table-column prop="userId" label="客户ID"></el-table-column>
      <el-table-column prop="userName" label="姓名"></el-table-column>
      <el-table-column prop="userMobile" label="手机号"></el-table-column>
      <el-table-column prop="helpProgress" label="助力进度"></el-table-column>
      <el-table-column prop="completeStatus" label="完成状态">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.completeStatus==0">未完成</el-tag>
          <el-tag type="primary" v-if="scope.row.completeStatus==1">已完成</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="startTime" label="参与时间"></el-table-column>
      <el-table-column prop="status" label="核销状态">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.status=='active'">未核销</el-tag>
          <el-tag type="primary" v-if="scope.row.status=='finish'">已核销</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="finishTime" label="核销时间"></el-table-column>
    </el-table>
    <div class="tab-page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="CollectLikesInfo.page"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="CollectLikesTotal"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import StatisticsAjax from "@/utils/https/modules/Statistics.request.js";
export default {
  name: "Praise", // 数据统计-营销数据-集赞
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      idInput: "",
      options: [
        { value: 0, label: "未完成" },
        { value: 1, label: "已完成" },
      ],
      statusoptions: [
        { value: "active", label: "未核销" },
        { value: "finish", label: "已核销" },
      ],
      value: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],

      CollectLikesInfo: {
        actId: 0,
        completeStatus: "",
        page: 1,
        size: 10,
        status: "",
        userId: "",
        userMobile: "",
        userName: "",
      },
      CollectLikesList: [],
      CollectLikesTotal: 0,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 用户集赞列表页码请求
    handleCurrentChange(val) {
      this.CollectLikesInfo.page = val;
      this.getQueryCollectLikesList();
    },
    searchPage() {
      this.CollectLikesInfo.page = 1;
      this.getQueryCollectLikesList();
    },
    // 查询用户集赞列表
    async getQueryCollectLikesList() {
      try {
        this.CollectLikesInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await StatisticsAjax.getQueryCollectLikesList(
          this.CollectLikesInfo
        );
        this.CollectLikesList = list;
        this.CollectLikesTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出用户集赞列表
    async exportData() {
      try {
        const data = await StatisticsAjax.getExportCollectLikesList(
          this.CollectLikesInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "用户集赞统计.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryCollectLikesList();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.Praise_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    div {
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      .el-input {
        width: 200px;
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // text-align: right;
    margin: 10px 0;
  }
}
</style>
